import React from 'react';
import FakeDataGenerator from './components/FakeDataGenerator';

function App() {
    return (
        <FakeDataGenerator />
    );
}

export default App;
